import React, {Component} from 'react';
import i18n from 'i18next';
import whiteLogo from '../img/logo_white.svg';
import fbIcon from '../img/fb.svg'
import instagramIcon from '../img/instagram.svg';
import tiktokIcon from '../img/tiktok.svg';
import outside_bw from "../img/outside_bw.jpg";
import '../sass/index_component.scss';
import Footer from "./Footer";
import Header from "./Header";

class App extends Component {
    constructor(props){
        super(props);
        const lang = this.props.match.params.lang ? this.props.match.params.lang : 'en';
        i18n.changeLanguage(lang);
    }
    render() {
        const {t} = this.props;
        return (
            <div className="App">
                <Header props={this.props}/>
                <div className="row hidden-sm hidden-xs">
                    <div className="col-sm-6">
                        <div className="about-us-text align-by-vertical">
                            <div className="logo">
                                <img src={whiteLogo} alt={"white logo"}/>
                            </div>
                            {/*<div className="donate-index-container">*/}
                            {/*    /!*<a href={'donate'} className={"donate-btn left"}>{t('donate->btn->text')}</a>*!/*/}
                            {/*    /!*<a href={'wish-list'} className={"donate-btn right"}>{t('wish-list->btn->text')}</a>*!/*/}
                            {/*    <a href={'donate'} className={"donate-btn"}>{t('donate->btn->text')}</a>*/}
                            {/*</div>*/}
                            <div className="about-us-image-mobile hidden-lg hidden-md">
                                <img src={outside_bw} alt="CatCafe outside photo desktop"/>
                            </div>
                            <div className="description">
                                {t('about-us->description')}
                            </div>
                            <div className="schedule">
                                {t('about-us->schedule->title')}
                                {/*{t('about-us->schedule->time')}*/}
                            </div>
                            <div className="price">
                                <table>
                                    <tr>
                                        <td/>
                                        <td>{t("about-us->price->mon-fri")}</td>
                                        <td>{t("about-us->price->sat-sun")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("about-us->price->30min-title")}</td>
                                        <td>{t("about-us->price->30min-mon-fri")}</td>
                                        <td>{t("about-us->price->30min-sat-sun")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("about-us->price->1hour-title")}</td>
                                        <td>{t("about-us->price->1hour-mon-fri")}</td>
                                        <td>{t("about-us->price->1hour-sat-sun")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("about-us->price->2hours-title")}</td>
                                        <td>{t("about-us->price->2hours-mon-fri")}</td>
                                        <td>{t("about-us->price->2hours-sat-sun")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("about-us->price->unlimited-title")}</td>
                                        <td>{t("about-us->price->unlimited-mon-fri")}</td>
                                        <td>{t("about-us->price->unlimited-sat-sun")}</td>
                                    </tr>
                                </table>
                                {/*{t("about-us->price->title")}<br/>*/}
                                {/*{t("about-us->price->0-5")}<br/>*/}
                                {/*{t("about-us->price->1")}<br/>*/}
                                {/*{t("about-us->price->2")}<br/>*/}
                                {/*{t("about-us->price->unlimited")}*/}
                            </div>
                            <div className="address">
                                {t("about-us->address1")}<br/>{t("about-us->address2")}<br/>
                                <a className="line-hover" target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/sRhbJM7X92z">
                                    {t("about-us->address->show-on-maps")}
                                    <i className="fa fa-map-marker"/>
                                </a>
                            </div>
                            <div className="contacts">+38 (073) 48-59-198<br/>info&#64;catcafe.ck.ua</div>
                            <div className="socials">
                                <a className="line-hover" target="_blank" rel="noopener noreferrer"
                                   href="https://www.facebook.com/catcafe.cherkassy">
                                    {t("about-us->social->we")}
                                    <img src={fbIcon} alt={"facebook icon"}/>
                                    </a>
                                <a className="line-hover" target="_blank" rel="noopener noreferrer"
                                   href="https://instagram.com/catcafe.che">
                                    {t("about-us->social->we")}
                                    <img src={instagramIcon} alt={"instagram icon"}/>
                                </a>
                                <a className="line-hover" target="_blank" rel="noopener noreferrer"
                                   href="https://www.tiktok.com/@catcafe.che">
                                    {t("about-us->social->we")}
                                    <img src={tiktokIcon} alt={"tiktok icon"}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="about-us-image align-by-vertical">
                            {/*<img src={outside_bw} alt="CatCafe outside photo"/>*/}
                            <video controls autoPlay muted>
                                <source src="https://content.catcafe.ck.ua/catcafe-intro-video.mov" type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
                <div className="row hidden-lg hidden-md">
                    <div className="col-sm-12">
                        <div className="about-us-text">

                            {/*<div className="donate-index-container">*/}
                            {/*    <a href={'donate'} className={"donate-btn left"}>{t('donate->btn->text')}</a>*/}
                            {/*    <a href={'wish-list'} className={"donate-btn right"}>{t('wish-list->btn->text')}</a>*/}
                            {/*</div>*/}
                            <div className="about-us-image-mobile">
                                <video width="100%" controls autoPlay muted>
                                    <source src="https://content.catcafe.ck.ua/catcafe-intro-video.mov" type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                                {/*<img src={outside_bw} alt="CatCafe outside photo mobile"/>*/}
                            </div>
                            <div className="logo">
                                <img src={whiteLogo} alt={"white logo"}/>
                            </div>
                            <div className="description">
                                {t('about-us->description')}
                            </div>
                            <div className="schedule">
                                {t('about-us->schedule->title')}
                                {/*{t('about-us->schedule->time')}*/}
                            </div>
                            <div className="price">
                                <table>
                                    <tr>
                                        <td/>
                                        <td>{t("about-us->price->mon-fri")}</td>
                                        <td>{t("about-us->price->sat-sun")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("about-us->price->30min-title")}</td>
                                        <td>{t("about-us->price->30min-mon-fri")}</td>
                                        <td>{t("about-us->price->30min-sat-sun")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("about-us->price->1hour-title")}</td>
                                        <td>{t("about-us->price->1hour-mon-fri")}</td>
                                        <td>{t("about-us->price->1hour-sat-sun")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("about-us->price->2hours-title")}</td>
                                        <td>{t("about-us->price->2hours-mon-fri")}</td>
                                        <td>{t("about-us->price->2hours-sat-sun")}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("about-us->price->unlimited-title")}</td>
                                        <td>{t("about-us->price->unlimited-mon-fri")}</td>
                                        <td>{t("about-us->price->unlimited-sat-sun")}</td>
                                    </tr>
                                </table>
                                {/*{t("about-us->price->title")}<br/>*/}
                                {/*{t("about-us->price->1")}<br/>*/}
                                {/*{t("about-us->price->2")}<br/>*/}
                                {/*{t("about-us->price->unlimited")}*/}
                            </div>
                            <div className="address">{t("about-us->address1")}<br/>{t("about-us->address2")}<br/>
                                <a className="line-hover" target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/sRhbJM7X92z">
                                    {t("about-us->address->show-on-maps")} <i className="fa fa-map-marker"/></a>
                            </div>
                            <div className="contacts">+38 (073) 48-59-198<br/>info&#64;catcafe.ck.ua</div>
                            <div className="socials">
                                <a className="line-hover" target="_blank" rel="noopener noreferrer"
                                   href="https://www.facebook.com/catcafe.cherkassy">
                                    {t("about-us->social->we")}
                                    <img src={fbIcon} alt={"facebook icon"} style={{width:"22px"}}/>
                                </a>
                                <a className="line-hover" target="_blank" rel="noopener noreferrer"
                                   href="https://instagram.com/catcafe.che">
                                    {t("about-us->social->we")}
                                    <img src={instagramIcon} alt={"instagram icon"} style={{width:"22px"}}/>
                                </a>
                                <a className="line-hover" target="_blank" rel="noopener noreferrer"
                                   href="https://www.tiktok.com/@catcafe.che">
                                    {t("about-us->social->we")}
                                    <img src={tiktokIcon} alt={"tiktok logo"} style={{width:"24px"}}/>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer/>
            </div>
        );
    }
}

export default App;
